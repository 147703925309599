.btn-primary {
	background-color: #bd51d4 !important;
}

/* Exclude .app-icon references to ensure that individual app icons are shown properly.
 This is needed for /apps page */
.app-logo:not(.app-icon .app-logo)  {
	content: url("/assets/z1n_core/images/z1n_favicon.png");
	height: 180px !important;
	margin-top: -60px;
	margin-left: 100px;
	margin-bottom: -15px;
}

.navbar-light {
	border-bottom: 1px solid #bd51d4;
}

/* hide login page head */
.page-card-head {
	display: none;
}

.page_content {
	margin-top: 100px;
}

/* sidebar on website */
.sidebar-item a.active {
	color: #bd51d4;
}

/* my account on website */
.account-info .col {
	border-bottom: 1px solid #000000
}

.my-account-header {
	color: #bd51d4;
}

/* login and signup page */
.for-login .page-card,
.for-signup .page-card,
.for-forgot .page-card,
.for-login-with-email-link .page-card {
	background-color: #000000 !important;
}

/* text on login and signup page */
.for-forgot .sign-up-message a,
.for-login-with-email-link .sign-up-message a,
.for-signup .sign-up-message a,
.for-email-login .sign-up-message a
{
	color: #9e9e9e !important;
}

/* Forgot password text */
.forgot-password-message > * {
	color: #9e9e9e !important;
}